import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ChannelStatus from '../ActiveChannelStatus';
import s from './s.module.less';
import useGetCorrectChannelStatus from 'hooks/useGetCorrectChannelStatus';
import { Channel } from 'types/common';
import { ChannelPayPlanTypeEnum, klarityPlanLevel } from 'types/channel';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';

interface IProps {
    isPremium?: boolean;
    locked?: boolean;
    item: Channel;
    isKlarityPlan?: boolean;
}

const ActiveChannelCard = ({
    isPremium,
    locked,
    item,
    isKlarityPlan,
}: IProps) => {
    const {
        id,
        name,
        logo,
        // tags,
        // website,
        intro,
        listStatus,
        payPlanType,
        originalPrice,
        billingCycle,
        kiwiPrice,
        freeTrialDays,
        // avgMonthGainPatient,
        // estimatedApplicationTime,
    } = item;

    const navigate = useNavigate();
    const statusText = useGetCorrectChannelStatus(listStatus);
    const [setting] = useSettings();
    const payPlanTypeSetting = setting[EnumFields.CHANNEL_PAY_PLAN_TYPE];

    const handleDetailPage = useCallback(() => {
        navigate(`/dashboard/channel-detail?channelId=${id}`);
    }, [id, navigate]);

    const tagRender = useMemo(() => {
        const tag = payPlanTypeSetting?.find((e) => e.value === payPlanType);
        const result = {
            cls: '',
            text: tag?.label,
        };

        if (payPlanType === ChannelPayPlanTypeEnum.FREE) {
            result.cls = s.free;
        } else if (payPlanType === ChannelPayPlanTypeEnum.PAID) {
            result.cls = s.paid;
        } else if (payPlanType === ChannelPayPlanTypeEnum.PREMIUM) {
            result.cls = s.premium;
        }

        return tag ? result : null;
    }, [payPlanType, payPlanTypeSetting]);

    let period = 'month';
    if (billingCycle === 'Quarterly') {
        period = 'quarter';
    }
    if (billingCycle === 'Yearly') {
        period = 'year';
    }

    const priceLabel = useMemo(() => {
        if (kiwiPrice && kiwiPrice !== originalPrice) {
            return (
                <div className={s.text}>
                    <span className={s.originalPrice}>Price: ${kiwiPrice || 0}/{period}</span>
                    {originalPrice && originalPrice > 0 ? <span className={s.cancelation}>(<span className={s.cancelationText}>${originalPrice || 0}/{period}</span>)</span> : null}
                </div>
            );
        }
        return (
            <div className={s.text}>
                <span className={s.originalPrice}>Price: ${originalPrice || 0}/{period}</span>
            </div>
        );
    }, [kiwiPrice, originalPrice, period]);

    return (
        <div className={s.channel} onClick={handleDetailPage}>
            <div className={s.channelInfo}>
                <div className={s.channelCardHeader}>
                    <div className={s.channelTitle}>
                        <div className={s.logo} style={{ backgroundImage: `url(${logo})` }} />
                        <div className={s.name}>{name}</div>
                    </div>
                    {/* <div className={s.statusTags}>
                        {tagRender && <div className={`${s.tag} ${tagRender.cls}`}>{tagRender.text}</div> }
                    </div> */}
                </div>
                <div className={s.intro}>{intro}</div>
            </div>
            {
                isPremium && (
                    <div className={s.premiumText}>
                        { freeTrialDays && freeTrialDays > 0 && <div className={s.text}>🔥 First {freeTrialDays} days free!</div> }
                        {/* <div className={s.text}>Price: ${originalPrice || 0}/{period}</div> */}
                        {priceLabel}
                    </div>
                )
            }
            {
                (!isKlarityPlan || !locked) && (
                    <div className={s.detail}>
                        <span className={s.label}>Status: </span>
                        <ChannelStatus showDot statusText={statusText} status={listStatus} />
                    </div>
                )
            }
            {locked && <div className={s.lockIcon} /> }
            {
                isKlarityPlan && locked && !isPremium && (
                    <div className={s.priceBox}>
                        <div className={`${s.originalPrice} ${locked ? '' : s.cancelation}`}>{`Price: $${originalPrice || 0}/${period}`}</div>
                        <div className={s.price}>Free in { locked ? `Klarity ${klarityPlanLevel[item.klarityPlanLevel - 1]} plan` : 'your plan' }!</div>
                    </div>
                )
            }
        </div>
    );
};

export default ActiveChannelCard;
