import { Button, Form, Input, message, Modal, Spin, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import s from './s.module.less';
import { useNavigate } from 'react-router-dom';
import { updateProviderEmailNotif, updateProviderNotificationConfig } from 'api/provider';
import commonS from 'styles/common.module.less';
import FormValidationHelper from 'utils/validation';
import { EMPTY_PLACEHOLDER, REQUIRED_FIELD } from 'constants/common';
import useProviderNotifyEmails from 'hooks/useProviderNotifyEmails';
import useProviderNotificationConfig from 'hooks/useProviderNotificationConfig';
import useProspectSettingInfo from 'hooks/useProspectSettingInfo';
import { saveProspectSettingSms } from 'api/prospect';

export type FormEmails = {
    list: { email: string }[],
    tel: string,
};

type Props = {
    onSuccess?: () => void,
}

const NotificationEmailEdit = (prop: Props) => {
    const { onSuccess, value } = prop;
    const navigate = useNavigate();
    const [notifyInfo, loadingNotify, refetch] = useProviderNotificationConfig();
    const [info, loadingInfo, refetchInfo] = useProspectSettingInfo();
    const [isEdit, setIsEdit] = React.useState(false);
    const [formInstance] = Form.useForm<FormEmails>();
    const [submiting, setSubmiting] = React.useState(false);
    const [updatingTelSetting, setUpdatingTelSetting] = React.useState(false);
    const tel = notifyInfo?.notificationPhoneNumber || notifyInfo?.tel;
    const [phoneValue, setPhoneValue] = React.useState('');
    React.useEffect(() => {
        const emailStr = notifyInfo?.notificationEmail;
        let emailArr: string[] = [];
        if (emailStr) {
            emailArr = emailStr.split(',')
        }
        if (formInstance && emailArr && emailArr.length > 0) {
            formInstance.setFieldsValue(
                {
                    list: emailArr?.map((email: string) => {
                        return { email }
                    })
                }
            )
        }
    }, [notifyInfo, formInstance]);

    const handleTelSwitchChange = async (check: boolean) => {
        setUpdatingTelSetting(true);
        const res = await saveProspectSettingSms(check);
        if (!res.error) {
            message.success('Notification setting has been updated');
            refetchInfo();
        }
        setUpdatingTelSetting(false);
    }
    useEffect(() => {
        formInstance.setFieldsValue({
            tel,
        })
    }, [tel]);

    const handleSubmit = async () => {
        try {
            const formValues = await formInstance.validateFields();

            const data: { email: string }[] = formValues.list;
            setSubmiting(true);
            const result = await updateProviderNotificationConfig(data ? data.map((i) => i.email).join(',') : '', formValues.tel, null);
            if (result.error) {
                //message.error(result.error);

            } else {
                message.success('Notify setting has updated');
                refetch();
                setIsEdit(false)
                if (onSuccess) {
                    onSuccess();
                }
            }
        } catch (e: any) {
            if (e.errorFields) {
                //form error
                return;
            }
            //const msg = e?.toString?.() || 'data error';
            //message.error(msg);
        } finally {
            setSubmiting(false);
        }
    }


    const emails: string[] = formInstance.getFieldValue(['list'])?.map((i: { email: string }) => i?.email);
    const hasSetEmail = !!notifyInfo?.notificationEmail;

    return (
        <div className={s.module}>
            <div className={s.mTitle}>
                <div className={s.left}>
                    Prospect notifications
                </div>
                <div className={s.right}>
                    {
                        isEdit && (
                            <div className={s.btnWrap}>
                                <Button
                                    onClick={() => {
                                        setIsEdit(false)
                                    }}
                                    className={s.btn}>
                                    Cancel
                                </Button>
                                <Button
                                    loading={submiting}
                                    onClick={handleSubmit}
                                    type='primary' className={s.btn}>
                                    Save
                                </Button>
                            </div>
                        )
                    }
                    {
                        !isEdit && (
                            <Button
                                onClick={() => {
                                    setIsEdit(true)
                                }}
                                className={s.btn}>
                                Edit
                            </Button>
                        )
                    }
                </div>
            </div>
            <div className={s.content}>
                {
                    isEdit &&
                    <Form
                        form={formInstance}
                        name="basic"
                        className={commonS.formStyle1}
                        autoComplete="off"
                        layout="vertical"
                        scrollToFirstError
                    >
                        <p className={s.listTitle}>E-mail addresses:</p>
                        <p className={s.listLabel}>Your prospect notifications will be send to the following email addresses:</p>
                        <Form.List name="list">
                            {
                                (fields, { add, remove }) => {
                                    return (
                                        <>
                                            {
                                                fields.map(({ key, name, ...restField }, index) => {
                                                    return (
                                                        <div className={s.formBody}>
                                                            <div className={s.row}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    rules={[
                                                                        { required: true, message: 'Email address is required' },
                                                                        { type: 'email', message: 'Please input correct email format' },
                                                                    ]}
                                                                    name={[name, 'email']}

                                                                >
                                                                    <Input style={{ width: '360px' }} />
                                                                </Form.Item>
                                                                <span className={s.remove} onClick={() => remove(index)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path d="M19 7L18.1327 19.1425C18.0579 20.1891 17.187 21 16.1378 21H7.86224C6.81296 21 5.94208 20.1891 5.86732 19.1425L5 7M10 11V17M14 11V17M15 7V4C15 3.44772 14.5523 3 14 3H10C9.44772 3 9 3.44772 9 4V7M4 7H20" stroke="#EF4444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                            {
                                                fields?.length < 5 &&
                                                <Form.Item>
                                                    <div className={s.add} onClick={() => add()}>Add {fields?.length > 0 ? 'another' : 'an'} email address</div>
                                                </Form.Item>
                                            }
                                        </>
                                    );
                                }
                            }
                        </Form.List>
                        <div>
                            <p className={s.listTitle}>Phone numbers:</p>
                            <p className={s.listLabel}>Your prospect notifications will be send to the following numbers, you can set up a new number for notification</p>
                            <Form.Item
                                label={null}
                                name="tel"
                                style={{ width: '430px', marginBottom: '4px' }}
                                rules={[
                                    { required: true, message: REQUIRED_FIELD },
                                ]}
                            >
                                <Input value={phoneValue} maxLength={18} onChange={(e) => setPhoneValue(e.target.value)} />
                            </Form.Item>
                        </div>

                    </Form>
                }
                {
                    !isEdit && (
                        <Spin spinning={loadingInfo}>
                            <div className={s.view}>
                                <div className={s.displayItem}>
                                    {
                                        hasSetEmail && (
                                            <>
                                                <div className={s.label}>
                                                    Your prospect notifications will be send to the following email addresses:
                                                </div>
                                                <div className={s.value}>
                                                    {emails?.join(' ,') || EMPTY_PLACEHOLDER}
                                                </div>
                                            </>
                                        )
                                    }
                                    {
                                        !hasSetEmail && (
                                            <div className={s.label}>
                                                Stay informed and never miss a new client's important message!  Click Edit to add up to 5 email addresses to receive prospect notifications.
                                            </div>
                                        )
                                    }
                                </div>
                                <div className={s.displayItem}>
                                    <div className={`${s.label} ${s.telLabel}`}>
                                        <span>Phone numbers:</span>
                                        <div className={s.switch}>
                                            <span style={{ marginRight: '8px' }}>Notification on</span>
                                            <Switch
                                                onChange={(checked) => {
                                                    handleTelSwitchChange(checked)
                                                }}
                                                loading={loadingInfo || updatingTelSetting}
                                                checked={!!info?.smsNotificationEnabled}></Switch>
                                        </div>
                                    </div>
                                    <div className={s.value}>
                                        {tel}
                                    </div>
                                </div>
                            </div>
                        </Spin>
                    )
                }
            </div>
        </div>
    );
};

export default NotificationEmailEdit;
