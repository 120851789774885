import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ActiveChannelCard from '../ActiveChannelCard';
import UpcomingChannelCard from '../UpcomingChannelCard';
import s from './s.module.less';
import { useRequest } from 'ahooks';
import { getLiveChannelList, getUpcomingChannelList, updateVoteById, createNewChannel } from 'api/channel';
import { Channel, EListStatus, Meta, ChannelPayPlanTypeEnum, EKlarityPlanLevel } from 'types/common';
import { Button, Dropdown, Form, Input, message, Popover, Select, Spin } from 'antd';
import commonS from 'styles/common.module.less';
import { openKlaritySubscriptionModal, openSubscriptionModal } from 'utils/globalLayerControl';
import useSettings from 'hooks/useSettings';
import { EnumFields } from 'types/enumerationData';
import ProviderChannelsStore from 'store/ProviderChannles';
import { getPlanTypeByInfo, getProviderKlarityPlanLevel, shouldShowKlarityPlanUI } from 'utils/provider';
import { DEFAULT_CHANNEL_LISTING_LIMITATION } from 'constants/common';
import { EChannelPayType, TSubscriptionType } from 'types/subscription';
import SubscriptionStore from 'store/Subscription';
import ChannelSelectModal from 'components/ChannelSelectModal';
import useLiveChannelsWithCategory from 'hooks/useLiveChannelsWithCategory';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
// import { filterAvailableChannel } from 'utils/channel';
import { getPPAChannelId } from 'utils/common';

interface INewChannel {
    name: string;
    website?: string;
}

interface IProps {
    isFree: boolean;
    setLiveChannelData: (data: Channel[]) => void;
}

const PAY_TYPE = 'payType';
const PRACTICE_TYPE = 'practiceType';
const PAYMENT_TYPE = 'paymentType';
const SORT = 'SORT';

const filterFieldMap = {
    [PAY_TYPE]: 'payPlanType',
    [PRACTICE_TYPE]: 'tags',
    [PAYMENT_TYPE]: 'tags',
    [SORT]: 'tags',
};

const KlarityChannelStorePage = ({
    isFree,
    setLiveChannelData,
    switchToMychannel,
}: IProps) => {
    const [homeInfo, loadingHome] = useProviderHomeInfo();
    const [showSubmitChannelSuccess, setShowSubmitChannelSuccess] = useState(false);
    const [getSubscription] = SubscriptionStore.useStore();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);
    const { data: channelListData = { data: { data: [] } }, run: liveChannelRun, loading: liveChannelLoading } = useRequest(getLiveChannelList) || {};
    const { data: upcomingChannelList, run, loading: upcomingChannelLoading } = useRequest(getUpcomingChannelList);
    const [interestForm] = Form.useForm();
    const [setting] = useSettings();
    const payPlanTypes = setting[EnumFields.CHANNEL_PAY_PLAN_TYPE];
    const channelTags = setting[EnumFields.CHANNEL_TAG];
    const channelFocusTypes = setting[EnumFields.CHANNEL_FOCUS];
    const sortTypesSetting = setting[EnumFields.CHANNEL_SORT_TYPE];
    const sortTypes = sortTypesSetting?.map((e: Meta) => ({
        key: e.value,
        label: e.label,
    })) || [];

    const [payPlanTypeOptions, setPayPlanTypeOptions] = useState<Meta[]>([]);
    const [practiceTypeOptions, setPracticeTypeOptions] = useState<Meta[]>([]);
    const [channelFocusTypeOptions, setChannelFocusTypeOptions] = useState<Meta[]>([]);
    const [filterPramas, setFilterPramas] = useState<Record<string, string | string[]>>({
        [PAY_TYPE]: '',
        [PRACTICE_TYPE]: '',
        [PAYMENT_TYPE]: [],
        [SORT]: '',
    });
    const [allLiveChannelWithCategory, loadingAllLiveChannelWithCategory] = useLiveChannelsWithCategory();
    const [liveChannelList, setLiveChannelList] = useState<Channel[]>([]);
    const pageIndex = useRef(1);
    const [pageSize] = useState(10);
    const [upcomingPageData, setUpcomingPageData] = useState([]);
    const [voting, setVoting] = useState(false);
    const [getProviderChannels] = ProviderChannelsStore.useStore();
    const myChannels = getProviderChannels('data');
    const [modalSelectType, setModalSelectType] = React.useState<undefined | ChannelPayPlanTypeEnum>(ChannelPayPlanTypeEnum.FREE);
    const [showChannelSelectModal, setShowChannelSelectModal] = React.useState(false);
    const [hasClickMoreStandar, setHasClickMoreStandar] = React.useState(false);
    const currentPlan = getSubscription('currentPlan');
    const orderBtns = useRef([TSubscriptionType.KLARITY_STARTER, TSubscriptionType.KLARITY_PLUS, TSubscriptionType.KLARITY_PRO]);
    const [currentOrder, setCurrentOrder] = useState<TSubscriptionType>(TSubscriptionType.KLARITY_PLUS);
    const [seletedLiveChannels, setSeletedLiveChannels] = useState<Channel[]>([]);

    // const isKlarity = currentPlan?.type === TSubscriptionType.KLARITY || currentPlan?.type === TSubscriptionType.KLARITY_STARTER || currentPlan?.type === TSubscriptionType.KLARITY_PLUS || currentPlan?.type === TSubscriptionType.KLARITY_PRO;
    const isStarter = currentPlan?.type === TSubscriptionType.KLARITY || currentPlan?.type === TSubscriptionType.KLARITY_STARTER;
    const isPlus = currentPlan?.type === TSubscriptionType.KLARITY_PLUS;
    const isPro = currentPlan?.type === TSubscriptionType.KLARITY_PRO;
    const myPlanLevel = getProviderKlarityPlanLevel(homeInfo);
    const showKlarityPlanUI = shouldShowKlarityPlanUI(homeInfo);

    const subText = 'We have curated a set of healthcare channels commonly used by healthcare professionals. Patients volume is generally correlated to the number of channels you are listed on.';

    // let subText = `You have 10 standard channels and ${currentPlan?.premiumChannelLimit || 2} premium channels in your plan.`;
    // if (isPlus) {
    //     subText = `As a Klarity Plus plan user, you can be listed on all standard channels and ${currentPlan?.premiumChannelLimit || 3} selected premium channels by one click.`;
    // }
    // if (isPro) {
    //     subText = `As a Klarity Pro plan user, you can be listed on all standard channels and ${currentPlan?.premiumChannelLimit || 5} selected premium channels by one click.`;
    // }

    useEffect(() => {
        const practiceTypes = channelTags?.filter((tag) => tag.value === 'In-person clinic only' || tag.value === 'Telehealth clinic only') || [];

        setPayPlanTypeOptions([
            {
                label: 'All',
                value: '',
            },
            ...(payPlanTypes || []),
        ]);
        setPracticeTypeOptions([
            {
                label: 'All',
                value: '',
            },
            ...(practiceTypes),
        ]);
        setChannelFocusTypeOptions(channelFocusTypes || []);
    }, [channelTags, payPlanTypes, channelFocusTypes]);

    useEffect(() => {
        if (channelListData?.data?.data && channelListData?.data?.data?.length > 0) {
            const channelsData = channelListData.data.data;
            const liveChannelsWithLock = channelsData.map((channel: Channel) => {
                const locked = myPlanLevel < channel.klarityPlanLevel;
                return {
                    ...channel,
                    locked,
                };
            });
            setLiveChannelList(liveChannelsWithLock);
            if (typeof setLiveChannelData === 'function') {
                setLiveChannelData(channelsData);
            }
        }
    }, [channelListData, myPlanLevel, setLiveChannelData]);

    const getUpcommingDataByPage = useCallback((data = []) => {
        const upcomingListData = data || [];

        const endIndex = pageIndex.current * pageSize;
        const currentData = upcomingListData.slice(0, endIndex);

        setUpcomingPageData(currentData);
        pageIndex.current += 1;
    }, [pageSize]);

    useEffect(() => {
        const upcomingListData = upcomingChannelList?.data?.data;

        if (pageIndex.current === 1 && upcomingListData && upcomingListData.length > 0) {
            getUpcommingDataByPage(upcomingListData);
        }
    }, [getUpcommingDataByPage, upcomingChannelList]);

    const handleUpdateVote = useCallback(async (id: number, voted: boolean) => {
        const params = {
            id,
            voteType: voted ? 0 : 1,
        };
        setVoting(true);

        try {
            const res = await updateVoteById(params);

            if (res && !res.error) {
                pageIndex.current = 1;
                run();
            } else {
                message.error(res.error);
            }
        } catch (e) {
            console.error(e);
        }

        setVoting(false);
    }, [run]);

    const submitNewChannel = useCallback(async (formData: INewChannel) => {
        setLoading(true);
        setShowSubmitChannelSuccess(false);
        const res = await createNewChannel(formData);

        setLoading(false);
        if (!res?.error) {
            messageApi.open({
                type: 'success',
                content: 'Submit interest channel successfully!',
            });
            interestForm.resetFields();
            setShowSubmitChannelSuccess(true);
        } else {
            messageApi.open({
                type: 'error',
                content: res.error,
            });
        }
    }, [interestForm, messageApi]);

    const handleShowUpgardePlan = useCallback(() => {
        if (showKlarityPlanUI) {
            openKlaritySubscriptionModal();
        } else {
            openSubscriptionModal();
        }
    }, [showKlarityPlanUI]);

    const handleFilter = (e: string | string[], type: string) => {
        filterPramas[type] = e;
        setFilterPramas({
            ...filterPramas,
        });
    };

    const handleClearFilter = useCallback(() => {
        const listData = channelListData?.data?.data || [];
        setFilterPramas({
            [PAY_TYPE]: '',
            [PRACTICE_TYPE]: '',
            [PAYMENT_TYPE]: [],
            [SORT]: '',
        });
        setLiveChannelList(listData);
    }, [channelListData]);

    const onOrderClick = (e: TSubscriptionType) => {
        setCurrentOrder(e);
    };

    const freeTip = useMemo(() => {
        let freeChannle = ['WebMD Standard', 'NextDoor'];
        if (myChannels?.freeList && myChannels?.freeList.length > 0) {
            freeChannle = myChannels.freeList.splice(0, 2).map((e: Channel) => e.name);
        }

        const channel1 = freeChannle[0];
        const channel2 = freeChannle[1] ? `and ${freeChannle[1]}` : '';

        return `Users on Free Plan can list on ${channel1} ${channel2}. Upgrade to Essential or Growth Membership Plan to gain access to 20+ channels to increase your online presence.`;
    }, [myChannels]);

    const spinning = liveChannelLoading || upcomingChannelLoading || loading || voting;

    //this is can listed chanel for current plan
    const allFreeChannelsCanListed = liveChannelList?.filter((e: Channel) => e.payPlanType === EChannelPayType.FREE) || [];
    const allStandardChannelsCanListed = liveChannelList?.filter((e: Channel) => {
        if (isStarter) {
            if (e.klarityPlanLevel !== undefined && e.klarityPlanLevel !== null && e.klarityPlanLevel > EKlarityPlanLevel.STARTER) {
                return false;
            }
        }
        return e.payPlanType === EChannelPayType.PAID;
    }) || [];
    const allPremiumChannelslsCanListed = liveChannelList?.filter((e: Channel) => {
        if (e.klarityPlanLevel === undefined || e.klarityPlanLevel === null || e.payPlanType !== EChannelPayType.PREMIUM) {
            return false;
        }
        if (isStarter && e.klarityPlanLevel <= EKlarityPlanLevel.STARTER && e.klarityPlanLevel !== 0) {
            return true;
        }
        if (isPlus && e.klarityPlanLevel <= EKlarityPlanLevel.PLUS && e.klarityPlanLevel !== 0) {
            return true;
        }
        if (isPro && e.klarityPlanLevel <= EKlarityPlanLevel.PRO && e.klarityPlanLevel !== 0) {
            return true;
        }
        return false;
    }) || [];

    const freeListWithListedAndUnderReview = allFreeChannelsCanListed?.filter((e: Channel) => e.listStatus === EListStatus.LISTED || e.listStatus === EListStatus.UNDER_REVIEW)?.sort((a, b) => {
        if (a.listStatus === EListStatus.LISTED) {
            return -1;
        }
        if (b.listStatus === EListStatus.LISTED) {
            return 1;
        }
        return 0;
    });
    const standardListWithListedAndUnderReview = allStandardChannelsCanListed?.filter((e: Channel) => e.listStatus === EListStatus.LISTED || e.listStatus === EListStatus.UNDER_REVIEW)?.sort((a, b) => {
        if (a.listStatus === EListStatus.LISTED) {
            return -1;
        }
        if (b.listStatus === EListStatus.LISTED) {
            return 1;
        }
        return 0;
    });

    const premiumListWithListedAndUnderReview = allPremiumChannelslsCanListed?.filter((e: Channel) => e.listStatus === EListStatus.LISTED || e.listStatus === EListStatus.UNDER_REVIEW)?.sort((a, b) => {
        if (a.listStatus === EListStatus.LISTED) {
            return -1;
        }
        if (b.listStatus === EListStatus.LISTED) {
            return 1;
        }
        return 0;
    });

    //include under review channels
    const listedStandardChanneIds = standardListWithListedAndUnderReview?.map((e: Channel) => e.id);
    const listedPremiumChanneIds = premiumListWithListedAndUnderReview?.map((e: Channel) => e.id);

    // const unListedFreeChannels = allFreeChannelsCanListed.filter((e: Channel) => { return !listedFreeChanneIds?.includes(e.id); });
    const unListedStandardChannels = allStandardChannelsCanListed.filter((e: Channel) => !listedStandardChanneIds?.includes(e.id));
    const unListedPremiumChannels = allPremiumChannelslsCanListed.filter((e: Channel) => !listedPremiumChanneIds?.includes(e.id));

    const showAddFreeOrStandardChannel = unListedStandardChannels.length > 0;
    const showAddPremiumChannel = unListedPremiumChannels.length > 0;

    const freeAndStandarListed = [...freeListWithListedAndUnderReview, ...standardListWithListedAndUnderReview];

    const allListed = unListedStandardChannels?.length === 0 && unListedPremiumChannels?.length === 0;

    let channelList: Channel[] = [];
    if (modalSelectType === ChannelPayPlanTypeEnum.PREMIUM) {
        channelList = unListedPremiumChannels;
    }
    let channelListWithCategory: Record<string, Channel[]> | undefined;

    if (!modalSelectType) {
        channelListWithCategory = { 'Premium channels': unListedPremiumChannels, 'Standard channels': unListedStandardChannels };
    } else if (modalSelectType === ChannelPayPlanTypeEnum.FREE) {
        channelListWithCategory = { 'Standard channels': unListedStandardChannels };
    }

    const premiumChannels = useMemo(() => {
        // isKlarity user and then hide PPA channel
        if (showKlarityPlanUI) {
            return allLiveChannelWithCategory.allLiveChannels.filter((channel) => channel.klarityPlanLevel === 0 && channel.payPlanType === ChannelPayPlanTypeEnum.PREMIUM && channel.id !== getPPAChannelId());
        }
        return allLiveChannelWithCategory.allLiveChannels.filter((channel) => channel.klarityPlanLevel === 0 && channel.payPlanType === ChannelPayPlanTypeEnum.PREMIUM);
    }, [allLiveChannelWithCategory, showKlarityPlanUI]);

    return (
        <Spin tip="Loading" size="large" spinning={spinning}>
            <div className={s.wrapper}>
                <div className={s.inner}>
                    {contextHolder}
                    <div className={s.channelBorad}>
                        {
                            allListed && (
                                <div className={s.allListed}>
                                    🎉 You’ve listed on all channels in your plan.
                                    <span
                                        onClick={() => {
                                            switchToMychannel?.();
                                        }}
                                        className={s.check}
                                    >Check in My channels
                                    </span>
                                </div>
                            )
                        }

                        {
                            !allListed && isStarter &&
                            <>
                                <p className={s.title}>
                                    Maximum your visibility and credibility with more channels
                                </p>
                                <p className={s.subtitle}>
                                    {subText}
                                    <span
                                        onClick={handleShowUpgardePlan}
                                        className={s.highlight}
                                    > Upgrade to unlock more channels
                                    </span>
                                </p>
                            </>
                        }
                        {
                            !allListed && (isPlus || isPro) &&
                            <>
                                <p className={s.title}>
                                    List on all available channels by one click
                                </p>
                                <p className={s.subtitle} style={{ marginTop: 8, marginBottom: 12 }}>
                                    {subText}
                                </p>
                                <Button
                                    onClick={() => {
                                        setModalSelectType(undefined);
                                        setShowChannelSelectModal(true);
                                    }}
                                    style={{ borderRadius: 6 }}
                                    type="primary"
                                >Get started
                                </Button>
                            </>
                        }
                        {
                            !allListed && isStarter &&
                            <div className={s.channelListWrap}>
                                <div className={s.label}>Premium channels included in the plan {`(${premiumListWithListedAndUnderReview.length}/${allPremiumChannelslsCanListed.length})`}</div>
                                <div className={`${s.value} ${s.channelLogos}`}>
                                    {
                                        premiumListWithListedAndUnderReview?.map((channel: Channel) => {
                                            const isListed = channel.listStatus === EListStatus.LISTED;
                                            if (isListed) {
                                                return (
                                                    <Popover content={<span>Live</span>} trigger="hover" key={channel.id}>
                                                        <img className={isListed ? s.channelLogo : `${s.channelLogo} ${s.channelLogoPending}`} src={channel.logo} />
                                                    </Popover>);
                                            }
                                            return (
                                                <Popover content={<span>Under review</span>} trigger="hover" key={channel.id}>
                                                    <img className={`${s.channelLogo} ${s.channelLogoPending}`} src={channel.logo} />
                                                </Popover>);
                                        })
                                    }
                                    {
                                        showAddPremiumChannel &&
                                        <span
                                            onClick={() => {
                                                setModalSelectType(ChannelPayPlanTypeEnum.PREMIUM);
                                                setShowChannelSelectModal(true);
                                            }}
                                            className={s.addIcon}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 51 50" fill="none">
                                                <rect x="1" y="0.5" width="49" height="49" rx="24.5" stroke="#9CA3AF" strokeDasharray="4 4" />
                                                <path d="M25.5 12.5V25M25.5 25V37.5M25.5 25H38M25.5 25L13 25" stroke="#6B7280" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    }
                                </div>
                                <div className={s.label}>Standard and basic channels included in the plan {`(${freeAndStandarListed.length}/${(allFreeChannelsCanListed.length + allStandardChannelsCanListed.length)})`}</div>
                                <div className={`${s.value} ${s.channelLogos}`}>
                                    {
                                        freeAndStandarListed?.map((channel: Channel) => {
                                            const isListed = channel.listStatus === EListStatus.LISTED;
                                            if (isListed) {
                                                return (
                                                    <Popover content={<span>Live</span>} trigger="hover" key={channel.id}>
                                                        <img className={isListed ? s.channelLogo : `${s.channelLogo} ${s.channelLogoPending}`} src={channel.logo} />
                                                    </Popover>);
                                            }
                                            return (
                                                <Popover content={<span>Under review</span>} trigger="hover" key={channel.id}>
                                                    <img className={`${s.channelLogo} ${s.channelLogoPending}`} src={channel.logo} />
                                                </Popover>);
                                        })
                                    }
                                    {
                                        showAddFreeOrStandardChannel &&
                                        <span
                                            onClick={() => {
                                                setModalSelectType(ChannelPayPlanTypeEnum.FREE);
                                                setShowChannelSelectModal(true);
                                            }}
                                            className={s.addIcon}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 51 50" fill="none">
                                                <rect x="1" y="0.5" width="49" height="49" rx="24.5" stroke="#9CA3AF" strokeDasharray="4 4" />
                                                <path d="M25.5 12.5V25M25.5 25V37.5M25.5 25H38M25.5 25L13 25" stroke="#6B7280" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    {
                        isFree &&
                        <div className={s.paidTip}>
                            <div className={s.starIcon} />
                            <div className={s.TipContent}>
                                <div className={s.tipText}>{freeTip}</div>
                                <a className={s.upgradeAction} onClick={handleShowUpgardePlan}>Upgrade plan</a>
                            </div>
                        </div>
                    }
                    <div className={s.klarityChannelPannel}>
                        <div className={s.planOrderBtns}>
                            {
                                orderBtns.current.map((item, index) => {
                                    const selected = item === currentOrder;
                                    return <div key={index} className={`${s.btn} ${selected ? s.selected : ''}`} onClick={() => onOrderClick(item)}>{`In ${item} plan`}</div>;
                                })
                            }
                        </div>
                    </div>
                    <div className={s.body}>
                        <div className={s.rChannelWrap}>
                            {
                                allLiveChannelWithCategory[currentOrder.toLocaleLowerCase()].map((item: Channel) => {
                                    const { locked } = item;

                                    return (
                                        <div key={item.id} className={s.rChannelItem}>
                                            <ActiveChannelCard
                                                isKlarityPlan
                                                locked={locked}
                                                key={item.id}
                                                item={item}
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    {
                        premiumChannels && premiumChannels.length > 0 && (
                            <div className={s.body}>
                                <h3>Premium Channels</h3>
                                <div className={s.subTitle}>Apply your listing application to various channels and begin attracting patients through those connections.</div>
                                <div className={s.rChannelWrap}>
                                    {
                                        premiumChannels.map((item: Channel) => {
                                            const { locked } = item;

                                            return (
                                                <div key={item.id} className={s.rChannelItem}>
                                                    <ActiveChannelCard
                                                        isPremium
                                                        isKlarityPlan
                                                        locked={locked}
                                                        key={item.id}
                                                        item={item}
                                                    />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                    <div className={s.body}>
                        <h3>Upcoming channels</h3>
                        <div className={s.subTitle}>Subscribe to notifications for upcoming channels of interest to stay informed and be first to apply, promoting your practice.</div>
                        <div className={s.uWrap}>
                            <div className={s.uChannelWrap}>
                                {upcomingPageData?.map((item: Channel) => {
                                    return (
                                        <div key={item.id} className={s.uChannelItem}>
                                            <UpcomingChannelCard
                                                key={item.id}
                                                item={item}
                                                onUpdateVote={handleUpdateVote}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={s.deviceMore}>
                                {
                                    upcomingChannelList?.data?.data && upcomingPageData.length < upcomingChannelList.data.data.length && <div className={s.moreButton} onClick={() => getUpcommingDataByPage(upcomingChannelList?.data?.data)}>More upcoming channels</div>
                                }
                            </div>
                        </div>
                        <h3>Channels of your interest</h3>
                        <div className={s.subTitle}>Didn&apos;t see your preferred channels listed above? Let us know which channels you prefer and we&apos;ll keep adding new options based on your feedback.</div>
                        <div className={s.interestForm}>
                            <Form
                                id="form"
                                form={interestForm}
                                name="newChannelForm"
                                className={commonS.formStyle1}
                                autoComplete="off"
                                layout="vertical"
                                onFinish={submitNewChannel}
                            >
                                <Form.Item
                                    label="Name of the channel"
                                    name="name"
                                    rules={[
                                        { required: true, message: 'The name of channel  is a required field' },
                                    ]}
                                >
                                    <Input className={s.interestInput} />
                                </Form.Item>
                                <Form.Item
                                    label="Website"
                                    name="website"
                                >
                                    <Input className={s.interestInput} />
                                </Form.Item>
                                <Form.Item>
                                    <Button loading={loading} className={s.submitButton} type="primary" htmlType="submit">Submit</Button>
                                </Form.Item>
                            </Form>
                            {
                                showSubmitChannelSuccess &&
                                <div className={s.submitChannelSuccess}>
                                    <div className={s.rightIcon} />
                                    <div className={s.successTip}>Thank you for sharing with us! If you have any other channels you're interested in, please feel free to add them.</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <ChannelSelectModal
                    myPlanLevel={myPlanLevel}
                    isKlarityUser={showKlarityPlanUI}
                    isOps={false}
                    title="List on all channels in the plan"
                    show={showChannelSelectModal}
                    channelList={channelList}
                    channelListWithCategory={channelListWithCategory}
                    onClose={() => {
                        setShowChannelSelectModal(false);
                    }}
                    onSubmit={() => {
                        setShowChannelSelectModal(false);
                        liveChannelRun();
                    }}
                    maxSelectedAmount={null}
                    showTopNav={modalSelectType === ChannelPayPlanTypeEnum.FREE}
                />
            </div>
        </Spin>
    );
};

export default KlarityChannelStorePage;
